
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Cell } from "vant";

@Component({
  components: { [Row.name]: Row, [Cell.name]: Cell },
})
export default class AutoComplete extends Mixins(Mixin) {
  @Prop({ default: false, type: Boolean }) public isSearched!: boolean;
  @Prop({ default: "", type: String }) public searchKeyword!: string;

  public associateKeywords: Array<string> = [];

  renderAssociate(): void {
    let searchKeyword = this.searchKeyword;
    if (searchKeyword) {
      this.$api.goodApi.search.findLenovo(searchKeyword, ({ data }) => {
        this.associateKeywords = (data.show || [])
          .concat(data.goodShop || [])
          .concat(data.goods || []);
      });
    } else {
      this.associateKeywords = [];
    }
  }

  chooseKeyword(searchKeyword: string): void {
    this.$emit("choose-keyword", searchKeyword);
  }

  hightLight(associateKeyword: string): string {
    const searchKeyword = this.searchKeyword;
    const hightLightStr = `<span style="color:#FFCD00">${searchKeyword}</span>`;
    const reg = new RegExp(searchKeyword, "gi");
    return associateKeyword.replace(reg, hightLightStr);
  }
}
