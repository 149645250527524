
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Row, Col, Search, Sticky, Icon } from "vant";
import AutoComplete from "@/views/Searchs/component/AutoComplete.vue";
import SearchHistory from "@/views/Searchs/component/SearchHistory.vue";
import CateTabs from "@/views/Searchs/component/CateTabs.vue";
import CategoryList from "@/components/CategoryList.vue";

@Component({
  components: {
    AutoComplete,
    SearchHistory,
    CateTabs,
    CategoryList,
    [Row.name]: Row,
    [Col.name]: Col,
    [Search.name]: Search,
    [Sticky.name]: Sticky,
    [Icon.name]: Icon,
  },
})
export default class ChannelDistributionSearchPage extends Mixins(Mixin) {
  isFocused = false; // 搜索框是否有焦点
  isShowAssociate = false; // 是否展示【联想】
  searchKeyword = ""; // 搜索关键字
  loading = false;
  currentPage = 1; // 滚动分页，当前在第几页
  pageFinished = false; // 滚动分页，是否已经没有数据了
  shows: Array<good.RecommendProductDto> = [];

  // 是否展示搜索历史，搜索关键字为空字符串时展示
  get isShowHistory(): boolean {
    return this.searchKeyword == "";
  }

  /**
   * 监听搜索关键字的变化
   *
   * @param searchKeyword 搜索关键字
   */
  @Watch("searchKeyword")
  searchKeywordChange(searchKeyword: string): void {
    if (searchKeyword == "") {
      // 关闭【联想】
      this.isShowAssociate = false;
      /**
       * 每次关键字变成空字符串且应该展示历史关键字时，渲染历史关键字并查询【推荐】数据
       * 因为展示搜索历史时总是要展示【推荐】
       */
      if (this.isShowHistory) {
        this.renderHistory();
      }
    } else if (this.isFocused) {
      // 每次关键字变成非空且搜索框有焦点时，打开并渲染【联想】
      this.isShowAssociate = true;
      this.renderAssociate();
    }
  }

  mounted(): void {
    let keyword = this.$route.query.keyword;
    if (keyword) {
      this.searchKeyword = String(keyword);
    }
    // 每次进入页面且应该展示历史关键字时，将"是否展开"重置为 false 并渲染历史关键字
    if (this.isShowHistory) {
      let searchHistory = this.$refs["search-history"] as SearchHistory;
      if (searchHistory) {
        searchHistory.resetIsShowAllHistory();
        searchHistory.renderHistory();
      }
    }
    this.doSearch();
  }

  /**
   * 搜索框聚焦事件，如果有搜索关键字则渲染【联想】
   */
  searchInputFocus(): void {
    this.isFocused = true;
  }

  /**
   * 渲染历史关键字
   */
  renderHistory(): void {
    this.$nextTick(() => {
      let searchHistory = this.$refs["search-history"] as SearchHistory;
      if (searchHistory) {
        searchHistory.renderHistory();
      }
    });
  }

  /**
   * 渲染联想关键字
   */
  renderAssociate(): void {
    this.$nextTick(() => {
      let autoComplete = this.$refs["auto-complete"] as AutoComplete;
      if (autoComplete) {
        autoComplete.renderAssociate();
      }
    });
  }

  /**
   * 点击【搜索历史】中的搜索关键字
   *
   * @param searchKeyword 搜索关键字
   */
  chooseKeyword(searchKeyword: string): void {
    this.searchKeyword = searchKeyword;
    this.doSearch();
  }

  /**
   * 点击【联想】中的搜索关键字
   *
   * @param searchKeyword 搜索关键字
   */
  chooseKeywordAutoComplete(searchKeyword: string): void {
    this.isShowAssociate = false;
    this.chooseKeyword(searchKeyword);
  }

  /**
   * 点击搜索框的搜索或者在搜索框点击回车
   * 先关闭【联想】然后搜索
   */
  clickSearchInput(): void {
    this.isShowAssociate = false;
    this.doSearch();
  }

  /**
   * 搜索 Tab 对应的数据（非【推荐】）
   */
  doSearch(): void {
    this.$nextTick(() => {
      let searchKeyword = this.searchKeyword;
      if (searchKeyword) {
        // 合并并缓存搜索历史
        this.UserModule.mergeSearchHistory_PERSIST(searchKeyword);
        this.refreshShow();
      }
    });
  }

  /**
   * 搜索演出对应的数据
   */
  refreshShow(): void {
    this.$nextTick(() => {
      let categoryList: CategoryList = this.$refs[
        "common-list"
      ] as CategoryList;
      if (categoryList) {
        categoryList.refresh();
      }
    });
  }
}
