
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Button, Image, Icon, Col, Empty, Dialog } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    [Row.name]: Row,
    [Empty.name]: Empty,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class SearchHistory extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public searchKeyword!: string;

  /**
   * 是否展开所有的历史关键字
   * 每次重新进入页面时重置为不展开
   * 一旦展开，只要停留在这个页面，就一直展开，不再收起
   */
  isShowAllHistory = false;
  delConfirm = false;
  historySearchKeysShow: Array<string> = []; // 用于展示的搜索关键字历史
  screenWidth = window.document.documentElement.clientWidth; // 屏幕宽度

  resetIsShowAllHistory(): void {
    this.isShowAllHistory = false;
  }

  /**
   * 渲染历史关键字列表
   */
  renderHistory(): void {
    this.historySearchKeysShow = this.UserModule.searchHistoryList;
    /**
     * 如果处于不展开所有历史关键字的状态，则计算一下要展开的关键字并重新渲染
     * 因为不同环境和不同字母的宽度都不通过，所以不用计算字数的方式
     * 而是先渲染所有关键字，再根据渲染出来的顶部和右侧位移来计算
     */
    if (!this.isShowAllHistory) {
      this.$nextTick(() => {
        this.historySearchKeysShow = this.getFixedHistory();
      });
    }
  }

  /**
   * 根据行数和到右边的宽度计算用于展示的历史关键字
   */
  getFixedHistory(): Array<string> {
    let fixedHistory: Array<string> = []; // 本次要展示的历史关键字
    let screenWidth = this.screenWidth; // 屏幕宽度
    let currentHeight = 0; // 当前元素到顶部的距离
    let currentRow = 0; // 当前元素的行数
    let historySearchKeysShow = this.historySearchKeysShow;
    for (let i = 0; i < historySearchKeysShow.length; i++) {
      let item = (
        this.$refs["history-keyword-item-" + i] as Array<HTMLElement>
      )[0];
      let offsetTop = item.offsetTop;
      if (offsetTop > currentHeight) {
        currentHeight = offsetTop;
        currentRow++;
      }
      if (currentRow >= 3) {
        if (currentRow == 3) {
          // 第 3 行的时候，看看右侧距离是否足够放一个展开按钮
          if (screenWidth - item.offsetLeft - item.clientWidth < 60) {
            break;
          }
        } else {
          break;
        }
      }
      fixedHistory.push(historySearchKeysShow[i]);
    }
    return fixedHistory;
  }

  /**
   * 展开所有的历史关键字
   */
  showMore(): void {
    monitorEvent("Search_ClickAll", "搜索历史点击展开"); // 埋点：搜索白页，搜索历史点击展开
    this.isShowAllHistory = true;
    this.historySearchKeysShow = this.UserModule.searchHistoryList;
  }

  chooseKeyword(keyword: string): void {
    this.$emit("choose-keyword", keyword);
  }

  deleteHistory(): void {
    monitorEvent("Search_ClickDelete", "点击清空搜索历史按钮"); // 埋点：搜索白页，点击清空搜索历史按钮
    if (this.UserModule.isLogin) {
      this.$api.goodApi.search.delHistory(() => {
        this.UserModule.SET_searchHistoryList_PERSIST([]);
      });
    } else {
      this.UserModule.SET_searchHistoryList_PERSIST([]);
    }
  }
}
