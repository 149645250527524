
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Tabs, Tab, Empty } from "vant";

export type CategoryType = "show" | "mall" | "none";

export type Category = {
  type: CategoryType;
  title: string;
};

@Component({
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Empty.name]: Empty,
  },
})
export default class CateTabs extends Mixins(Mixin) {
  // 搜索关键字
  @Prop({ default: "", type: String }) searchKeyword!: string;
  // 是否展示 Tab 上的数字
  @Prop({ default: true, type: Boolean }) showNumber!: boolean;

  categoryList: Array<{ type: string; title: string }> = [];
  tabFlag = "";
  typeCountMap: Record<CategoryType, number> = {
    mall: 0,
    show: 0,
    none: 0,
  };

  setTab(tabName: string): void {
    if (tabName == "mall") {
      this.categoryList = [
        { type: "mall", title: "商品" },
        { type: "show", title: "演出" },
        { type: "none", title: "" },
        { type: "none", title: "" },
      ];
    } else {
      this.categoryList = [
        { type: "show", title: "演出" },
        { type: "mall", title: "商品" },
        { type: "none", title: "" },
        { type: "none", title: "" },
      ];
    }
    this.tabFlag = tabName;
  }

  /**
   * 提供给父组件获取选中的 Tab 标识符的方法
   */
  getTabFlag(): string {
    return this.tabFlag;
  }

  /**
   *  获取 Tab 对应的查询结果数量
   *
   * @param type 分类标识符
   */
  getTabNumber(type: string): string {
    if (!this.showNumber) {
      return "";
    }
    if (type != "show" && type != "mall" && type != "none") {
      return "";
    }
    let count = this.typeCountMap[type];
    if (count > 99) {
      return "99+";
    }
    if (count < 1) {
      return "";
    }
    return String(count);
  }

  /**
   * 查询各 Tab 数量
   */
  getCategoryList(): void {
    this.$api.goodApi.search.findCategoryMatchCountV2(
      {
        keyword: this.searchKeyword,
      },
      ({ data }) => {
        let typeCountMap: Record<CategoryType, number> = this.typeCountMap;
        typeCountMap.mall = data.commodityCount || 0;
        typeCountMap.show = data.productCount || 0;
        this.typeCountMap = typeCountMap;

        if (this.categoryList.length === 1) {
          if (this.categoryList[0].title === "商品") {
            this.$emit("CateSearch", {
              type: "mall",
              length: this.categoryList.length,
            });
          }
          if (this.categoryList[0].title === "演出") {
            this.$emit("CateSearch", {
              type: "home",
              length: this.categoryList.length,
            });
          }
        }
      }
    );
  }

  /**
   * 点击 Tab 事件
   *
   * @param tabFlag 当前 Tab 的标识符
   */
  clickTab(tabFlag: "mall" | "show"): void {
    this.$emit("click-tab", tabFlag);
  }
}
